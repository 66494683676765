import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import styled from "styled-components"
import { mq } from "../utils/presets"
import Indexdogs from "../components/indexdogs"
import CarloImage from "../components/carloimage"

const Imgwrapper = styled.div`
  width: 100%;
  height: 60vh;
  ${mq.tablet} {
    height: calc(100vh - 150px);
  }
  ${mq.xl} {
    height: calc(100vh - 96px);
  }
  position: relative;
  overflow: hidden;
`

const Headertext = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 1rem;
  h2 {
    display: inline-block;
    color: white;
    font-weight: 300;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid white;
    border-top: 2px solid white;
    margin: 0 auto;
  }
  h2:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 3px solid white;
    z-index: -1;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Professionelle Fellpflege in Bayreuth"
      keywords={[
        `Hundefriseur`,
        `Hundepflege`,
        `Hundesalon`,
        `Hundeboutique`,
        `Hundescheren`,
        `Hundetrimmen`,
        `Fellpflege`,
        `Carding`,
        `Scheren`,
        `Trimmen`,
        `Hund`,
        `Hunde`,
        `Bayreuth`,
      ]}
    />

    <Imgwrapper>
      <CarloImage />
      <Headertext>
        <h2>Professionelle Fellpflege für glückliche Hunde</h2>
      </Headertext>
    </Imgwrapper>

    <Indexdogs />
  </Layout>
)

export default IndexPage
