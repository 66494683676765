import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { mq } from "../utils/presets"

const POST_CARLO_QUERY = graphql`
  query CARLOQUERY {
    file(relativePath: { regex: "/carlo/" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 60vh;
  ${mq.tablet} {
    height: calc(100vh - 150px);
  }
  ${mq.xl} {
    height: calc(100vh - 96px);
  }
  position: relative;
  overflow: hidden;
`

const Image = styled(Img)`
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const CarloImage = () => (
  <StaticQuery
    query={POST_CARLO_QUERY}
    render={data => (
      <ImageWrapper>
        <Image
          objectFit="cover"
          objectPosition="50% 50%"
          fluid={data.file.childImageSharp.fluid}
          alt="Carlo"
        />
      </ImageWrapper>
    )}
  />
)

export default CarloImage
