import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { mq, colors } from "../utils/presets"

const Cardwrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`

const Carditem = styled.div`
  ${mq.tablet} {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
    margin-top: 2rem;
    justify-content: center;
    &:nth-child(even) {
      .textwrapper {
        order: 2;
      }
      .dogimage {
        order: 1;
      }
    }
  }
  .textwrapper {
    padding: 1rem;
    max-width: 1600px;
    ${mq.tablet} {
      flex: 0 1 auto;
      width: 50%;
      max-width: 650px;
    }
    h2 {
      text-align: center;
      /* margin: 1rem auto 2rem; */
      padding: 1rem 0;
      border-bottom: 3px solid ${colors.black};
      border-top: 3px solid ${colors.black};
    }
    ${mq.xl} {
      h2 {
        text-align: left;
        border: none;
        margin-bottom: 0;
        padding-bottom: 0.5rem;
        max-width: 25rem;
        margin: 0;
      }
    }
  }
`

const Dogpic = styled(Img)`
  height: auto;
  ${mq.tablet} {
    align-self: center;
    flex: 0 1 auto;
    width: 300px;
    height: 300px;
    border-radius: 150px;
  }
`

const Indexdogs = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(slug: { regex: "/home/" }) {
          acf {
            daten {
              title
              text
              bild {
                localFile {
                  childImageSharp {
                    sizes(maxWidth: 800) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Cardwrapper>
        {data.wordpressPage.acf.daten.map(item => (
          <Carditem key={item.title}>
            <div className="textwrapper">
              <h2 className="desc">{item.title}</h2>
              <p className="desc">{item.text}</p>
            </div>
            <Dogpic
              className="dogimage"
              objectFit="cover"
              objectPosition="50% 50%"
              sizes={item.bild.localFile.childImageSharp.sizes}
              alt={item.title}
            />
          </Carditem>
        ))}
      </Cardwrapper>
    )}
  />
)

export default Indexdogs
